.mover {
  position: relative;
  margin-top: 10px;
  animation: move 1s infinite linear;    
}

.spinner {
  position: relative;
  animation: spin 13s infinite linear;
}


@keyframes spin { 
  100% { 
      transform: rotate(360deg);
  } 
}

@keyframes move {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
}